class Component {
  initState() {
    this.pickerTogglers = document.querySelectorAll('.lang-picker__current')

    this.pickerTogglers.forEach((item) => {
      item.addEventListener('click', () => {
        item.classList.toggle('state-active')
      })
    })

    window.addEventListener('click', (event) => {

      if (!event.target.classList.contains('.lang-picker') && !event.target.closest('.lang-picker')) {
        this.pickerTogglers.forEach((item) => {
          item.classList.remove('state-active')
        })
      }
    })
  }

  init() {
    // this.initState()
  }
}

export default new Component()
