import Header from './header'
import Partners from './partners'
import LangPicker from './lang-picker'
import Sticker from './sticker'
import Footer from './footer'
import heroMain from './hero-main'
import LoadMore from './load-more'
import BlogLoadMore from './blog-load-more'
import GalleryCarousel from './gallery-carousel'
import Team from './team'
import ModalGallery from './modal-gallery'
import ApproachSteps from './approach-steps'
import ApproachStepsDropdown from './approach-steps-dropdown'
import Animate from './animate'
import Cookie from './cookie'
import PageTransition from './page-transition'
import UnderlineMultiple from './underline-multiple'
import Pinterest from './pinterest'
import HeroVideo from './heroVideo'
import Sprig from './sprig'
import filterBtn from './filter-btn'

export const shouldInit = [
  Header,
  Partners,
  LangPicker,
  Sticker,
  Footer,
  heroMain,
  LoadMore,
  BlogLoadMore,
  GalleryCarousel,
  Team,
  ModalGallery,
  ApproachSteps,
  ApproachStepsDropdown,
  Animate,
  Cookie,
  PageTransition,
  UnderlineMultiple,
  Pinterest,
  HeroVideo,
  Sprig,
  filterBtn
]

export const shouldUpdate = [
  Header,
  Partners,
  LangPicker,
  Sticker,
  Footer,
  heroMain,
  LoadMore,
  BlogLoadMore,
  GalleryCarousel,
  Team,
  ModalGallery,
  ApproachSteps,
  ApproachStepsDropdown,
  Animate,
  UnderlineMultiple,
  HeroVideo,
  Sprig,
  filterBtn
]
