class Component {
  constructor() {
    this.onScroll = this.onScroll.bind(this)
  }

  initState() {
    this.content = document.querySelector('.hero-main__content')
    this.logoContainer = document.querySelector('.hero-main__logo-container')
    this.logo = this.logoContainer?.querySelector('.hero-main__logo')
    this.isMobile = window.matchMedia('(max-width: 767px)').matches
    this.scale = 1
    this.offset = this.logo ? this.logo.getBoundingClientRect().top : 0
  }

  render() {
    if (this.isMobile) {
      if (this.scale <= 0.45) {
        this.logo.style.transform = 'scale(0.45)'
        this.logoContainer.style.position = 'absolute'
        this.logoContainer.style.top = '29.6rem'
      } else {
        this.logo.style.transform = `scale(${this.scale})`
        this.logoContainer.style.position = 'fixed'
        this.logoContainer.style.top = 0
      }
      return
    }

    if (this.scale <= 0.22) {
      this.logo.style.transform = 'scale(0.22)'
    } else {
      this.logo.style.transform = `scale(${this.scale})`
    }
  }

  onScroll() {
    const contentRect = this.content.getBoundingClientRect()
    this.scale = contentRect.top / (contentRect.height - this.offset * 2) + 1

    requestAnimationFrame(() => {
      this.render()
    })
  }

  init() {
    this.initState()

    if (this.logo) {
      window.addEventListener('scroll', this.onScroll)
    }
  }

  destroy() {
    window.removeEventListener('scroll', this.onScroll)
  }
}

export default new Component()
